<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import Monitor from "@/services/Monitor";


export default {
    components: { Layout, PageHeader, countTo },
    page: {
        title: "Queues Status",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    async mounted() {
        try {
            this.showLoader = true
            await this.getQueuesStats();
            this.showLoader = false
            this.setupInterval();
        } catch (error) {
            console.error('Error during component initialization:', error);
        }
    },
    methods: {
        async getQueuesStats() {
            try {
                const response = await Monitor.getQueuesStats();
                this.queuesData = response.data.data;
            } catch (error) {
                console.error('Error in getQueuesStats:', error);
                this.error = error.response?.data?.error || '';
            }
        },

        setupInterval() {
            this.timer = setInterval(async () => {
                try {
                    await this.getQueuesStats();
                } catch (error) {
                    console.error('Error in interval:', error);
                }
            },60000);
        },
    },
    data() {
        return {
            title: "Queues Status",
            items: [
                // {
                //     text: "Queues",
                //     href: "/notifications",
                // },
            ],
            queuesData: {},
            showLoader:false,
            error: ''
        };
    },
    destroyed() {
        clearInterval(this.timer);
    },
    middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

        <div class="row" v-if="!showLoader">
            <div class="col-md-6 col-xl-4" v-for="(item, index) in queuesData" :key="item.id">
                <div class="card">
                    <div class="card-header">
                        <h5>Queue version: {{index}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="float-end mt-4">
                            <i v-if="item.status=='active'" class="mdi mdi-48px mdi-check-bold me-2 text-success"></i>
                            <i v-else class="mdi mdi-48px mdi-alarm-light-outline me-2 text-danger animate-flicker"></i>
                        </div>
                        <div>
                            <h1 class="mb-1 mt-1 text-secondary">
                                <span data-plugin="counterup" :class="{'text-warning': item.cntQueues>0, 'text-success': item.cntQueues<1,}">
                                    <countTo :startVal="0" :endVal="item.cntQueues" :duration="10"></countTo>
                                </span>
                            </h1>
                            <p class="mb-0 text-secondary" style="font-size: large;">Jobs in Queue</p>
                        </div>
                        <p class="text-muted mt-3 mb-0" style="font-size: large;">
                            <span class="text-success me-3">
                                <i class="mdi mdi-24px mdi-check-outline me-2"></i> 
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="item.cntSuccess" :duration="10"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-danger me-3">
                                <i class="mdi mdi-24px mdi-close-outline me-2"></i>  
                                <b>
                                    <span data-plugin="counterup ">
                                        <countTo :startVal="0" :endVal="item.cntFailed" :duration="10"></countTo>
                                    </span>
                                </b>
                            </span>
                            <span class="text-danger">
                                <router-link :to="{ path: `/monitor/queues/${index}`}">
                                    Details
                                </router-link>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
      <div v-else>
          <div class="card-body d-flex align-items-center justify-content-center" >
              <b-spinner large></b-spinner>
          </div>
      </div>

  </Layout>
</template>
